import { axios, store, removeLoginIfLogedOut, abortController } from './config/axios-config'

const getVagasData = async (listaOcupacoes, estado = null, municipio = null) => {
    store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false })
    try {
        const response = await axios.post('/backend/vagasData',{listaOcupacoes, estado, municipio},{
            cancelToken: abortController.token
        })
        store.dispatch({ type: 'GET_VAGAS_DATA', list: response.data })
        store.dispatch({ type: 'SET_DATA_VAGA_SELECIONADA', value: {value: null, label: "Todas as datas disponíveis"} });
        return response.data
    } catch (error) {
        removeLoginIfLogedOut(error)
        store.dispatch({ type: 'SET_LOADING', loading: false })
        store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true })
    }
}
export default getVagasData