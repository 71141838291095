import { axios, store, removeLoginIfLogedOut, abortController } from './config/axios-config'

const getUserStates = async (list=false) => {

    setTimeout(() =>{store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false })}, 0)
    
    try {
        let response = null
        
        if(list){
            response = await axios.get('/user/data/savedUserState',{
                cancelToken: abortController.token
            })

            return response.data
        }
        else{
            response = await axios.get('/user/data/savedUserState',{
                cancelToken: abortController.token
            })

            // Criando um novo campo no Redux
            store.dispatch({ type: 'SET_USER_SAVED_STATES', states: response.data })
        }
        
    } catch (error) {
        removeLoginIfLogedOut(error)
        store.dispatch({ type: 'SET_LOADING', loading: false })
        store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true })
    }

}

export default getUserStates